/**
 * This file is part of the DNB DNA 2020 application.
 *
 * (c) APT AS
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import React, { useEffect, useState } from 'react';
import { GlobalError } from '@dnb/eufemia';
import MainLayout from 'components/layout/MainLayout';
import { PageProps } from 'gatsby';
import { CXM_PAGE_LOADING_STATE } from 'utils/constants';
import { push404PageLoadedEvent } from 'utils/datalayer';

const NotFound: React.FC<PageProps> = () => {
  const [isMount, setMount] = useState(false);

  useEffect(() => {
    setMount(true);
    push404PageLoadedEvent();
  }, []);
  if (!isMount) {
    return <div>{CXM_PAGE_LOADING_STATE}</div>;
  }
  return (
    <MainLayout
      theme={{
        backgroundColor: 'inherit',
        color: 'inherit',
        logoColor: 'inherit',
        linkColor: 'inherit',
      }}
    >
      <GlobalError status="404" />
    </MainLayout>
  );
};

export default NotFound;
