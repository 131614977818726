declare global {
  interface Window {
    dnbDataLayer: DataLayerEventData[]
  }
}

export type DataLayerEventData = {
  event: DataLayerEvent,
  page: {
    info: {
      destination_url:
      string
    },
    error?: SearchEventErrorData,
    search?: SearchEventData
  },
  user?: {
    party_id: string
  }
}

export enum DataLayerEvent {
  GENERIC = 'GenericEvent',
  PAGE_LOADED = 'PageLoaded',
  SECTION_VIEW = 'SectionView',
  SEARCH = 'Search'
}

export type SearchEventData = {
  name: string,
  results: number,
  term: string,
  filter?: string
}

export type SearchEventErrorData = {
  type: string,
  message: string,
  value: string,
}