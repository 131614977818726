import { DataLayerEvent, DataLayerEventData, SearchEventErrorData, SearchEventData } from "./types";

if (typeof window !== 'undefined') {
  window.dnbDataLayer = window.dnbDataLayer || []
}
const pushToDataLayer = (data: DataLayerEventData) => {
  window.dnbDataLayer.push(data)
}
export const pushPageLoadedEvent = (pathname: string, { name, results, term }: SearchEventData, user?: {
  party_id: string
}) =>
  pushToDataLayer({
    event: DataLayerEvent.PAGE_LOADED,
    page: {
      info: {
        destination_url:
          window.location.protocol + '//' + window.location.host + pathname
      },
      search: {
        name, results, term, filter: 'all'
      }
    },
    user
  }
  )

export const pushErrorPageLoadedEvent = (pathname: string, { type, message, value }: SearchEventErrorData) =>
  pushToDataLayer({
    event: DataLayerEvent.PAGE_LOADED,
    page: {
      info: {
        destination_url:
          window.location.protocol + '//' + window.location.host + pathname
      },
      error: {
        type, message, value
      }
    },
  }
  )

export const push404PageLoadedEvent = () =>
  pushToDataLayer({
    event: DataLayerEvent.PAGE_LOADED,
    page: {
      info: {
        destination_url:
          window.location.protocol + '//' + window.location.host + '/404-page-not-found'
      }
    }
  })
